/* only animate if the device supports hover */
@media (hover: hover) {
    #creditcard {
      /*  set start position */
      transform: translateY(110px);
      transition: transform 0.1s ease-in-out;
      /*  set transition for mouse enter & exit */
    }
  
    #money {
      /*  set start position */
      transform: translateY(180px);
      transition: transform 0.1s ease-in-out;
      /*  set transition for mouse enter & exit */
    }
  
    button:hover #creditcard {
      transform: translateY(0px);
      transition: transform 0.2s ease-in-out;
      /*  overide transition for mouse enter */
    }
  
    button:hover #money {
      transform: translateY(0px);
      transition: transform 0.3s ease-in-out;
      /*  overide transition for mouse enter */
    }
  }
  
  @keyframes bounce {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-0.25rem);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  .button:hover .button__text span {
    transform: translateY(-0.25rem);
    transition: transform .2s ease-in-out;
  }
  
  /* styling */
  
  @import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400&display=swap");
  
  body {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .button {
    border: none;
    outline: none;
    padding: 0.5rem 60px 0.5rem 1rem;
    position: relative;
    border-radius: 8px;
    letter-spacing: 0.7px;
    background-color: rgb(0, 102, 115);
    color: #fff;
    font-size: 21px;
    font-family: "Lato", sans-serif;
    cursor: pointer;
    box-shadow: rgb(0 9 61 / 20%) 0px 4px 8px 0px;

  }
  
  .button:active {
    transform: translateY(1px);
  }
  
  .button__svg {
    position: absolute;
    overflow: visible;
    bottom: 6px;
    right: 0.2rem;
    height: 140%;
  }
  