
.contentimg {
    border-radius: 7px 7px 0 0;
    min-width: 100%;

}



.thumb {
    padding-top: .75rem;
}




    .thumb .Title h4{
        color: rgb(37, 37, 37);
        font-size:1rem; 
        font-weight: 600;
        white-Space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 13.5rem ;
        font-family: 'Amiri';
    }
    .thumb .Title h6{
        color: rgb(110, 110, 110);
        font-size:.7rem; 
        font-weight: 500;
        white-Space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 13rem ;
        
    }





