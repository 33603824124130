@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


*{
  font-family: 'Poppins', sans-serif;
}
.container{
  position: relative;
  padding: 2px 0px;
}

.container .card{
  position: relative;
  /* width: 200px;
  height: 300px; */
  background: #004a53;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: black .5px .5px 30px;
  margin-bottom: 50px;
  margin-top: 20px;
}

.container .card:hover{

  box-shadow: none;
}

.container .card:before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #027585;
  clip-path: circle(300px at 50% 50%);
  transition: 0.5s ease-in-out;

}

.container .card:hover:before{
  clip-path: circle(200px at 50% -20%);
  
}



.container .card .imgBx{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10000;
  width: 100%;
  height: 130px;
  transition: 0.5s;
}

.container .card:hover .imgBx{
  top: 0%;
  transform: translateY(0%);
    
}

.container .card .imgBx img{
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-0deg);
  width: 100px;
}

.container .card .contentBx{
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
  text-align: center;
  transition: 1s;
  z-index: 10;
}

.container .card:hover .contentBx{
  height: 170px;
}

.container .card .contentBx h2{
  position: relative;
  font-weight: 600;
  letter-spacing: 1px;
  color: #fff;
}

.container .card .contentBx .size {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  transition: 0.5s;opacity: 0;
  visibility: hidden;
  padding-top: 0;
  padding-bottom: 0;
}

.container .card:hover .contentBx .size{
  opacity: 1;
  visibility: visible;
  transition-delay: 0.5s;
}

.container .card:hover .contentBx .color{
  opacity: 1;
  visibility: visible;
  transition-delay: 0.6s;
}


.container .card .contentBx h2{
  color: #fff;
  font-weight: 600;
  font-size: 24px;
  letter-spacing: 2px;
  margin: 10px;
}


.container .card .contentBx .size h3{
  color: #fff;
  font-weight: 300;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
}
/* 
.container .card .contentBx .size span{
  width: 26px;
  height: 26px;
  text-align: center;
  line-height: 26px;
  font-size: 14px;
  display: inline-block;
  color: #111;
  background: #fff;
  margin: 0 5px;
  transition: 0.5s;
  color: #111;
  border-radius: 4px;
  cursor: pointer;
} */

.container .card .contentBx .button{


  
  display: inline-block;
  padding: 4px 40px;
  background: #fff;
  height: 2.5rem;
  border-radius: 5px;
  margin-bottom: 10px;
  text-decoration: none;
  font-weight: 600;
  color: #111;
  opacity: 0;
  transform: translateY(50px);
  transition: 0.5s;
}


.container .card .contentBx .button:before{

content: "";
background: linear-gradient(
  45deg,
  #ff0000,
  #ff7300,
  #fffb00,
  #48ff00,
  #00ffd5,
  #002bff,
  #7a00ff,
  #ff00c8,
  #ff0000
);
position: absolute;
top: -2px;
left: -2px;
background-size: 500%;
z-index: -1;
filter: blur(5px);
-webkit-filter: blur(5px);
width: calc(100% + 4px);
height: calc(100% + 4px);
animation: glowing-button-85 20s linear infinite;
transition: opacity 0.3s ease-in-out;
border-radius: 5px;
}



@keyframes glowing-button-85 {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.container .card .contentBx .button:after{
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  /* background: #222; */
  background: rgb(255, 255, 255);
  left: 0;
  top: 0;
  border-radius: 5px;
}



.container .card:hover .contentBx .button{
  opacity: 1;
  transform: translateY(0px);
  transition-delay: 0.75s;
  
}
