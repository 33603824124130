.x{
    max-width: 100%;
}
.Maingallery {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
    -webkit-column-width: 25%;
    -moz-column-width: 25%;
    column-width: 25%;
    margin: 10px;
    

}
.pics {
    -webkit-transition: all 350ms ease;
    transition: all 350ms ease;
    cursor: pointer;
    margin-bottom: 15px;
    position: relative;
    border: 1px solid rgb(228, 228, 228);
    border-radius:  5px;

}


.pics .imgx {
    border: 1px solid rgb(233, 233, 233);
    border-bottom: 0px;
    border-radius: 10px 10px 0px 0px;
    box-shadow: rgba(0, 0, 0, .15) 0 3px 9px 0;



}



.Filesumb {
    position: absolute;
    background-color: white;
    border: 1px  solid rgb(235, 235, 235);
    border-top: 0px;
    border-radius: 0px 0px 10px 10px ;
    box-shadow: rgba(0, 0, 0, .15) 0 3px 9px 0;


}

.pics .Filesumb img {
    width: 42px;
    height: 42px;
    border: 1px solid rgb(199, 199, 199);
}

.Filesumb .Title {
    padding-top: 10px;
}

.Filesumb .Title h4{
    color: rgb(0, 0, 0);
    font-size:small; 
    font-weight: 600;
    white-Space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 12.5rem ;
}

.Filesumb .Title h6{
    color: rgb(110, 110, 110);
    white-Space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 12rem ;

    font-size: x-small;
    font-weight: 600;
}
.Lovelist {
    position: absolute;
    top: 0px;
    right: 5px;
}
.pics:hover {
opacity: 90%;
}
@media (max-width:1400px) {
    .Maingallery {
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-count: 3;   
    }
    
}
@media (max-width:900px) {
    .Maingallery {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;   
    }
    
}
@media (max-width:700px) {
    .Maingallery {
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;   
    }



    .Filesumb .Title h4{
        color: rgb(0, 0, 0);
        font-size:small; 
        font-weight: 600;
        white-Space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 18.5rem ;
    }
    
    .Filesumb .Title h6{
        color: rgb(110, 110, 110);
        white-Space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 12rem ; }




}
