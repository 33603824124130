.Filesumb {
    width: 100%;
    height: 60px;
    display: flex;
    position: sticky;  
    bottom: 0;
} 
.Filesumb img {
    height: 45px;
    width: 45px;
    border-radius: 50%;
    border: 1px solid rgb(0, 131, 120);
    margin-right: 10px;
    margin-top: 8px;
    margin-left: 8px;
}
.Filesumb .title {
    padding-left: 5px;
    padding-top: 8px;
    color: rgb(2, 0, 0);
}
.Filesumb .title .likelist {
    display: flex;
    justify-content: space-around;
}
.Filesumb .title .likelist h5 {
    padding-left: 15px;
}
.Filesumb.Filesumb h3 {
    padding-top: 8px;
}